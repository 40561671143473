import {
    getGoodsDetail,
    receiveCoupon,
    getBrandGoods,
    recommendGoods,
    collectGoods,
    cancelCollectGoods,
    shareGoods,
    appointmentNotice,
    activityDetail
} from '@/utils/goods.js';
import goodsSku from '@/components/goodsSku';
import { provide, ref } from 'vue';
import { weichatTools } from '@/utils/weichatShare.js';
import useClipboard from "vue-clipboard3"
export default {
    components: {
        goodsSku
    },
    setup() {
        let isSku = ref(false); // 商品规格选择弹窗
        provide('issku', isSku);
        let platfrom = ref('goodsdetail');
        provide('platfrom', platfrom)
        return {
            isSku
        }
    },
    data() {
        return {
            isShowPage: false,
            goodsId: '',
            goodsData: '',
            goodsPic: [],
            active: 0,
            isMoreDetail: false,
            goodsList: [],
            bkGoodsList: [],
            tagIndex: 0,
            couponInfo: '',
            buyGoodsNum: 1,
            rewardIntergal: 0,
            yhPrice: 0,
            remarkArr: [],
            specName: '请选择商品',
            mallEntityGoodSpecList: [],
            isGoodsSku: false,
            userType: '',
            showRules: false, // 查看返佣规则
            isDaPerson: '', // 身份 0=普通会员，1=团长、种草达人
            isLike: 2, // 是否收藏
            isWxGz: false,
            wxSubStatus: 0,
            showXz: false,
            clerkContent: '',
            activityId: '',
            activityStatus: '',
            currentTime: {
                hours: '00',
                minutes: '00',
                seconds: '00'
            },
            timer: null,
            activityInfo: '',
            acStatus: '',
            isAppoint: ''
        }
    },
    created() {
        this.goodsId = this.$route.query.goodsId;
        this.activityId = this.$route.query.activityId;
        this.userType = this.$cookies.get('userType');
        this.isDaPerson = this.$cookies.get('isDaPerson');
        this.getActivityDetailFunc();
    },
    methods: {
        // 获取活动详情
        getActivityDetailFunc() {
            let data = {
                activityId: this.activityId
            }
            activityDetail(data).then(res => {
                console.log('获取活动详情', res.data)
                this.activityInfo = res.data.activityInfo;
                // 活动状态
                this.acStatus = res.data.activityInfo.activityStatus;
                this.computedFunc(this.activityInfo);
                this.getGoodsDetailFunc();
            })
        },
        // 获取商品详情
        getGoodsDetailFunc() {
            let data = {
                goodId: this.goodsId,
                memberId: this.$cookies.get('memberId'),
                activityId: this.activityId, // status=2(预热)，status=3(进行中)，status=4(已结束)
            }
            getGoodsDetail(data).then(res => {
                if (res.code == 1) {
                    let aData = JSON.parse(JSON.stringify(res.result.mallEntityGood));

                    // 判断是否显示微信公众号
                    this.wxSubStatus = res.result.wxSubStatus;
                    if (res.result.wxSubStatus == 0) {
                        this.isWxGz = true;
                    }

                    // 是否已预约
                    this.isAppoint = res.result.isAppoint;

                    // 轮播图
                    let arr = aData.picture ? aData.picture.split(',') : [];
                    this.goodsPic = arr;

                    // 优惠券
                    this.couponInfo = res.result.coupon;

                    // 商品详情信息
                    let remarkArr = aData.remarkPics ? aData.remarkPics.split('|') : [];
                    this.remarkArr = remarkArr;

                    // 规格
                    this.mallEntityGoodSpecList = aData.mallEntityGoodSpecList;
                    this.goodsData = aData;

                    // 处理商品描述信息
                    this.clerkContent = this.goodsData.clerk ? this.goodsData.clerk.replace(/\n/g, '<br/>') : '';
                    console.log('this.clerkContent', this.clerkContent)

                    this.isLike = res.result.isLike;
                    sessionStorage.setItem('goodsdetail', JSON.stringify(this.goodsData));
                    let shareUrl = 'https://wx.linglong-goods.com/yllService/api/oAuth2/goodDetailShare?gid=' + this.goodsId + '&shareMemberId=' + this.$cookies.get('memberId');
                    weichatTools.share.shareFunc(this.goodsData.name, '灵龙惠生活“理性种草寻好货”', shareUrl, this.goodsPic[0]);
                    this.shareGoodsFunc();
                    setTimeout(() => {
                        this.isGoodsSku = true;
                    }, 500)

                    this.isShowPage = true;

                    this.getBrandGoodsFunc();
                    this.rewardYhGoodsFunc();
                }
            })
        },
        // 计算活动结束时间
        computedFunc(data) {
            let startTime = new Date(data.activityStartTime).getTime();
            let endTime = new Date(data.activityEndTime).getTime();
            let nowTime = new Date().getTime();
            let isActivityStatus = 0;

            // 活动未开始
            if (startTime > nowTime) {
                isActivityStatus = 0;
                this.currentDownTimeFunc(startTime, nowTime, endTime, isActivityStatus);
            } else if (nowTime <= endTime) {
                // 活动进行中
                isActivityStatus = 1;
                this.currentDownTimeFunc(startTime, nowTime, endTime, isActivityStatus);
            } else {
                // 活动已结束
                isActivityStatus = 2;
            }
            clearTimeout(this.timer);
            this.activityStatus = isActivityStatus;
            this.timer = setTimeout(() => {
                this.computedFunc(data)
            }, 1000)
        },
        // 倒计时
        currentDownTimeFunc(stime, ntime, etime, status) {
            let pTime = 0;
            if (status == 0) {
                pTime = parseInt(stime - ntime);
            } else if (status == 1) {
                pTime = parseInt(etime - ntime);
            }

            let hours = Math.floor(pTime / 1000 / 60 / 60 % 24);
            let minutes = Math.floor(pTime / 1000 / 60 % 60);
            let seconds = Math.floor(pTime / 1000 % 60);

            console.log('hours:' + hours, 'minutes:' + minutes, 'seconds: ' + seconds)
            this.currentTime = {
                hours: this.addZeroFunc(hours),
                minutes: this.addZeroFunc(minutes),
                seconds: this.addZeroFunc(seconds)
            }
        },
        // 补0
        addZeroFunc(data) {
            return data >= 10 ? data : '0' + data;
        },
        // 分享
        shareGoodsFunc() {
            let data = {
                goodId: this.goodsId,
                memberId: this.$cookies.get('memberId')
            }
            shareGoods(data).then(res => {
                console.log('分享', res)
                weichatTools.share.shareFunc(this.goodsData.name, '灵龙惠生活“理性种草寻好货”', this.shareGoodQRcode, this.goodsPic[0]);
            })
        },
        // 点击查看更多详情
        readDetailFunc() {
            this.isMoreDetail = !this.isMoreDetail
        },
        // 领取优惠券
        receiveCouponFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                couponId: this.couponInfo.id
            }
            receiveCoupon(data).then(res => {
                // if (!this.$cookies.get('isLogin')) {
                //     this.$router.push({
                //         path: '/login'
                //     })
                // }
                if (res.code == 0) {
                    this.$toast('领取成功！')
                } else {
                    this.$toast(res.message)
                }
            })
        },
        // 立即购买 打开商品规格弹窗
        openGuigeFunc() {
            // if (!this.$cookies.get('isLogin')) {
            //     this.$router.push({
            //         path: '/login'
            //     })
            // }

            this.isSku = true;
            this.$refs.goodssku.activityId = this.activityId;
            this.$refs.goodssku.specArrFunc();
        },
        // 购买商品
        buyGoodsFunc(val, specname) {
            console.log('2222', val, specname)
            this.buyGoodsNum = val;
            this.specName = specname;
            this.rewardYhGoodsFunc();
        },
        // 可得积分  优惠金额
        rewardYhGoodsFunc() {
            this.rewardIntergal = this.buyGoodsNum * this.goodsData.score;
            this.yhPrice = !this.couponInfo ? (this.goodsData.originalPrice - this.goodsData.salePrice) * this.buyGoodsNum : (this.goodsData.originalPrice - this.goodsData.salePrice - this.couponInfo.value) * this.buyGoodsNum;
        },
        onClickIcon(type) {
            console.log('11111', '')
            if (type == 'home') {
                this.$router.push({
                    path: '/'
                })
            }
        },
        // 点击切换品牌商品  爆款推荐
        selectTagFunc(idx) {
            this.tagIndex = idx;
            this.goodsList = [];
            if (idx == 0) {
                this.getBrandGoodsFunc();
            } else {
                this.recommendGoodsFunc();
            }
        },
        // 获取 品牌的其他商品
        getBrandGoodsFunc() {
            let data = {
                brandId: this.goodsData.brandId,
                pageNo: 1,
                pageSize: 6
            }
            getBrandGoods(data).then(res => {
                if (res.result) {
                    this.goodsList = res.result.mallEntityGoodList;
                }
            })
        },
        // 爆款推荐
        recommendGoodsFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodName: '',
                sortMethod: 1,
                pageNo: 1,
                pageSize: 6,
                channel: 0, //(0=普通专区,1=限时积分专区,2=达人专区)
                categoryFirstId: '',
                tag: '爆款推荐'
            }
            recommendGoods(data).then(res => {
                console.log('爆款推荐', res)
                if (res.result) {
                    this.bkGoodsList = res.result.goodList;
                }
            })
        },
        // 跳转商品详情
        gotoPageFunc(id) {
            this.$router.replace({
                path: '/goodsDetail',
                query: {
                    goodsId: id
                }
            })
            setTimeout(() => {
                location.reload()
            }, 100)
        },
        // 处理商品图片
        farmPicFunc(img) {
            let url = img ? img.split(',')[0] : '';
            return url;
        },
        // 跳转品牌信息
        goBrandFunc() {
            this.$router.push({
                path: '/brandIntroduce',
                query: {
                    brandId: this.goodsData.brandId
                }
            })
        },
        // 跳转到我的权益
        gotoProfitFunc() {
            this.$router.push({
                path: '/memberInterest'
            })
        },
        // 成为达人
        applyDaPersonFunc() {
            this.showRules = false;
            this.$router.push({
                path: '/daPerson'
            })
        },
        // 跳转到分享
        goShareFunc() {
            window.location.href = window.location.origin + '/share?goodsId=' + this.goodsId
        },
        // 售后规则
        goRefundPageFunc() {
            window.location.href = "/agreement/sale.html"
        },
        // 查看返佣规则
        readProfitFunc() {
            // if (!this.$cookies.get('isLogin')) {
            //     this.$router.push({
            //         path: '/login'
            //     })
            // }
            this.showRules = true;
        },
        // 收藏 取消收藏
        collectGoodsFunc() {
            if (this.isLike == 2) {
                let data = {
                    memberId: this.$cookies.get('memberId'),
                    goodsId: this.goodsId
                }
                collectGoods(data).then(res => {
                    console.log('收藏', res)
                    this.isLike = 1
                })
            } else {
                let data = {
                    memberId: this.$cookies.get('memberId'),
                    goodsIds: this.goodsId
                }
                cancelCollectGoods(data).then(res => {
                    console.log('取消收藏', res)
                    this.isLike = 2
                })
            }
        },
        // 查看更多商品
        clickMoreFunc() {
            this.$router.push({
                path: ''
            })
        },
        // 复制文案
        async copyFunc() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(this.goodsData.clerk)
                this.$toast.success("已复制文案")
            } catch (e) {
                console.error(e)
            }
        },
        // 通知预约
        appointmentNoticeFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                activityId: this.activityId,
                goodId: this.goodsId,
                startTime: this.activityInfo.activityStartTime
            }
            appointmentNotice(data).then(res => {
                console.log('通知预约', res.data)
                this.$toast.success('通知预约成功！');
                this.getGoodsDetailFunc();
            })
        }
    },
}